var client;
var google;
let scriptEle = document.createElement("script");
let mapKey = '';

let route = `https://maps.googleapis.com/maps/api/js?key=${getKey()}&libraries=places&language=en&callback=getKey`;
scriptEle.setAttribute("src", route);

let stripeRouteEle = document.createElement("script");
route = 'https://js.stripe.com/v3/';
stripeRouteEle.setAttribute("src", route);

if (window.location.href.includes('https://business.financemutual.com/')) {
    (function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "pr4r0rjq71");
}

function getKey() {
    if (window.location.href.includes('.financemutual.com')) {
        mapKey = 'AIzaSyAa0GBtCtHP69nQ3EhYc69G2xyXXXAQCYE';
    } else {
        mapKey = 'AIzaSyAa0GBtCtHP69nQ3EhYc69G2xyXXXAQCYE'
    }
    return mapKey;
}
// document.head.appendChild(scriptEle);
document.head.appendChild(stripeRouteEle);